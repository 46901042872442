import React from "react"
import Modal from "react-modal"
import * as styles from "../../styles/components/vision-modal.module.scss"
import specialCharacterReplace from "../../utils/special-character-replace"

const GeneralTableModal = (props) => (
  <Modal
    isOpen={props.open}
    onRequestClose={props.close}
    className={styles.visionModal}
    overlayClassName={styles.visionOverlay}
  >
    <div>
      <button onClick={props.close}>
        <img src="/images/ico-close.svg" width="24" alt="x" />
      </button>
      <div className={styles.theme}>
        <h2 dangerouslySetInnerHTML={{ __html: specialCharacterReplace(props.title) }} />
        <table className={styles.archiveTable}> 
          { props.tableData }
        </table>
      </div>
    </div>
  </Modal>
)
export default GeneralTableModal