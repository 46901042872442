import React from "react"
import { Link, graphql } from "gatsby"
import * as styles from "../../styles/page/second.module.scss"

// 共通コンポーネント
import Meta from "../../components/layouts/meta"
import Layout from "../../components/layouts/default"
import BreadCrumb from "../../components/layouts/bread-crumb"
import RelatedResource from "../../components/parts/related-resource"

import AboutCategory from "../../components/parts/about-category"

// markup
const IndexPage = ({data}) => {
  const markdownRemark = data.markdownRemark
  const { frontmatter } = markdownRemark
  const { title, description, slug } = frontmatter

  const outline = [
    {
      title: '目的・概要',
      body: (
        <>
          <p className="leading-8 mb-2">2050年カーボンニュートラルの実現に向け、NEDOに基金を造成し、<em>野心的な目標にコミットする企業等</em>に対して、<em>最長10年間、研究開発・実証から社会実装までを継続して支援</em></p>
        </>
      ),
    },
    {
      title: '目標',
      body: (
        <div className={`${styles.imageWrapper} mb-1`}>
          <img src={'../../images/about/outline-02.png'} alt="" width="500" />
        </div>
      ),
    },
    {
      title: '支援対象',
      body: (
        <>
          <p className="leading-8 mb-2"><em>グリーン成長戦略において実行計画を策定している重点分野又は「GX 実現に向けた基本方針」に基づく今後の道行きが示されている主要分野</em>であり、<em>政策効果が大きく、社会実装までを見据えて長期間の継続支援が必要な領域に重点化</em>して支援</p>
          <ul className={styles.checkList}>
            <li>従来の研究開発プロジェクトの平均規模（200億円）以上を目安</li>
            <li>国による支援が短期間で十分なプロジェクトは対象外</li>
            <li>社会実装までを担える、企業等の収益事業を行う者を主な実施主体（中小・ベンチャー企業の参画を促進、大学・研究機関の参画も想定）</li>
            <li>国が委託するに足る革新的・基盤的な研究会開発要素を含むことが必要</li>
          </ul>
        </>
      ),
    },
    {
      title: '成果最大化に向けた仕組み',
      body: (
        <>
          <p className="leading-8 mb-2">研究開発の成果を着実に社会実装へ繋げるため、<em>企業等の経営者に対して、長期的な経営課題として粘り強く取り組むことへのコミットメント</em>を求める</p>
          <p>（企業等の経営者に求める取り組み）</p>
          <ul className={`${styles.noStyleList} mb-2`}>
            <li>応募時の長期事業戦略ビジョンの提出</li>
            <li>経営者によるWGへの出席・説明</li>
            <li>取り組み状況を示すマネジメントシートの提出</li>
          </ul>
          <p>（コミットメントを高める仕組みの導入）</p>
          <ol className={`${styles.circleDecimalList} mb-3`}>
            <li>取り組み状況が不十分な場合の事業中止・委託費の一部返還等</li>
            <li>目標の達成度に応じて国がより多く負担できる制度（インセンティブ措置）の導入</li>
          </ol>
        </>
      ),
    },
    {
      title: '実施体制',
      body: (
        <>
          <p className="mb-1">外部専門家の知見も取り入れ、関係機関が緊密に連携した、<em>透明性・実効性の高いガバナンス体制</em>を構築</p>
          <div className={`${styles.imageWrapper} mb-1`}>
            <img src={'../../images/about/outline-05.png'} alt="" width="900" />
          </div>
          <p className="mb-1"><small>※1 プロジェクトの2030年目標・研究開発項目・対象技術の成熟度（TRL等）・予算規模等を記載した計画書（素案をWGで審議）<br />※2 関係省庁のプロジェクト担当課室も含む</small></p>
        </>
      ),
      wide: true,
    },
    {
      title: '事業の流れ',
      body: (
        <div className={`${styles.imageWrapper} mb-1`}>
          <img src={'../../images/about/outline-06.png'} alt="" width="1000" />
        </div>
      ),
      wide: true,
    },
  ]

  const energy = [
    {
      title: <>① 洋上風力<br />太陽光・地熱産業<br /><small>（次世代再生可能エネルギー）</small></>,
      image: '/images/about/wind-pv-geothermal.png',
    },
    {
      title: <>② 水素・燃料<br />アンモニア産業</>,
      image: '/images/about/hydrogen.png',
    },
    {
      title: <>③ 次世代<br />熱エネルギー産業</>,
      image: '/images/about/next-generation-thermal-energy.png',
    },
    {
      title: <>④ 原子力産業</>,
      image: '/images/about/nuclear.png',
    },
    
  ]

  const deliveryManufacturing = [
    {
      title: <>⑤ 自動車・<br />蓄電池産業</>,
      image: '/images/about/car-battery.png',
    },
    {
      title: <>⑥ 半導体・<br />情報通信産業</>,
      image: '/images/about/semiconductor.png',
    },
    {
      title: <>⑦ 船舶産業</>,
      image: '/images/about/ship.png',
    },
    {
      title: <>⑧ 物流・人流・<br />土木インフラ産業</>,
      image: '/images/about/logistics.png',
      small: true,
    },
    {
      title: <>⑨ 食料・<br />農林水産業</>,
      image: '/images/about/agriculture.png',
    },
    {
      title: <>⑩ 航空機産業</>,
      image: '/images/about/aircraft.png',
    },
    {
      title: <>⑪ カーボンリサイクル<br />・マテリアル産業</>,
      image: '/images/about/carbon-recycle.png',
      small: true,
    },
  ]

  const homeOffice = [
    {
      title: <>⑫ 住宅・建築物<br />産業・次世代電力<br />マネジメント産業</>,
      image: '/images/about/recycle.png',
    },
    {
      title: <>⑬ 資源循環<br />関連産業</>,
      image: '/images/about/housing.png',
    },
    {
      title: <>⑭ ライフスタイル<br />関連産業</>,
      image: '/images/about/life-style.png',
    },
  ]

  return (
    <Layout>
      <Meta 
        title={title}
        description={description}
        path={slug}
      />

      <BreadCrumb
        parentLink="/about/"
        parentTitle="グリーンイノベーション基金事業概要等"
        currentTitle={title}
      />

      <div className={styles.pageTitle}>
        <h1 className={styles.pageTitleHeading}>{title}</h1>
      </div>

      <main className={styles.pageContents}>
        <p className="pb-3">2020年10月、我が国は「2050年カーボンニュートラル」を宣言し、2050年までに、温室効果ガスの排出を全体としてゼロにする目標を掲げました。この目標は、従来の政府方針を大幅に前倒すものであり、並大抵の努力で実現できるものではありません。エネルギー・産業部門の構造転換や、大胆な投資によるイノベーションといった現行の取組を大幅に加速することが必要です。</p>
        <p className="pb-1">このため、グリーンイノベーション基金事業（以下「基金事業」という。）により、NEDOに2兆円<sup>※</sup>（2021年3月時点）の基金を造成し、官民で野心的かつ具体的な目標を共有した上で、これに経営課題として取り組む企業等に対して、最長10年間、研究開発・実証から社会実装までを継続して支援します。</p>
        <p className="pb-6"><small><sup>※</sup>令和4年度第2次補正予算により3000億円が積み増しされており、さらに令和5年度当初予算により4564億円が積み増しされます（2023年7月時点）。</small></p>

        <h2 className={styles.pageContentsHeading2}>グリーンイノベーション基金事業の基本方針（概要）</h2>
        
        
        <div className={styles.aboutOutline}>
          {
            outline.map((item, index) => {
              return <div className={`${styles.aboutOutlineItem} ${item.wide ? styles.aboutOutlineItemWide : ''}`}>
                <h3 className={styles.aboutOutlineTitle}>
                  <span className={styles.aboutOutlineTitleNo}>{`00${index + 1}`.slice(-2)}</span>
                  <span className={styles.aboutOutlineTitleText}>{item.title}</span>
                </h3>
                <div className={styles.aboutOutlineBody}>
                  {item.body}
                </div>
              </div>
            })
          }
        </div>
        <p className="mb-8 text-right"><small>詳細は<a href="https://www.meti.go.jp/policy/energy_environment/global_warming/gifund/" className={styles.linkText} target="_blank" rel="noopener noreferrer">グリーンイノベーション基金事業の基本方針（経済産業省）</a>をご参照ください。</small></p>
        

        <h2 className={styles.pageContentsHeading2}>グリーン成長戦略において実行計画を策定した重点14分野</h2>
        <div className={styles.aboutCategory}>
          <div className={`${styles.aboutCategoryLeft} ${styles.aboutCategoryColumn}`}>
            <h3>エネルギー関連産業</h3>
            <div className={styles.aboutCategoryItemWrapper}>
              <AboutCategory items={energy} />
              <p><small>※NEDOは、法律により専ら原子力を対象とする研究開発を実施・補助することはできません。</small></p>
            </div>
          </div>
          <div className={`${styles.aboutCategoryCenter} ${styles.aboutCategoryColumn}`}>
            <h3>輸送・製造関連産業</h3>
            <div className={styles.aboutCategoryItemWrapper}>
              <AboutCategory items={deliveryManufacturing} />
            </div>
          </div>
          <div className={`${styles.aboutCategoryRight} ${styles.aboutCategoryColumn}`}>
            <h3>家庭・オフィス関連産業</h3>
            <div className={styles.aboutCategoryItemWrapper}>
              <AboutCategory items={homeOffice} />
            </div>
          </div>
        </div>
        <p className="mb-8 text-right"><small>詳細は<a href="https://www.meti.go.jp/policy/energy_environment/global_warming/ggs/index.html" className={styles.linkText} target="_blank" rel="noopener noreferrer">2050年カーボンニュートラルに伴うグリーン成長戦略（経済産業省）</a>をご参照ください。</small></p>
        
        <div className={`${styles.contentsBannerWrap} pb-6`}>
          <Link to="/about/public-contribution/" className={`${styles.contentsBannerCard} ${styles.publicContribution}`}>
            <h2 className={styles.contentsBannerCardTitle}>
              公募等情報
            </h2>
            <span className={styles.contentsBannerCardBullet}>詳しく見る</span>
          </Link>
          <Link to="https://www.nedo.go.jp/itaku-gyomu/ZZRM_100001_00029.html" target="_blank" rel="noopener noreferrer" className={`${styles.contentsBannerCard} ${styles.relatedInformation}`}>
            <h2 className={styles.contentsBannerCardTitleMultiline}>
              グリーンイノベーション基金事業<br />委託・助成事業の手続き
            </h2>
            <span className={styles.contentsBannerCardBullet}>詳しく見る</span>
          </Link>
          <Link to="/about/related-information/" className={`${styles.contentsBannerCard} ${styles.relatedInformation}`}>
            <h2 className={styles.contentsBannerCardTitle}>
              関連制度・リンク
            </h2>
            <span className={styles.contentsBannerCardBullet}>詳しく見る</span>
          </Link>
        </div>

        {/*<PublicOffering border="true"></PublicOffering>*/}
        <RelatedResource styles={styles} />

      </main>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: {slug: {eq: $slug}}) {
      html
      frontmatter {
        title
        description
        slug
      }
    }
  }
`