import React from "react"
import * as styles from "../../styles/page/second.module.scss"

export default function AboutCategory(props) {
  const { items } = props
  
  return (
    <>
      { 
        items.map((item, index) => {
          return <div className={styles.aboutCategoryItem} key={index}>
            <div className={styles.aboutCategoryItemImage}><div className={styles.aboutCategoryItemImageInner}><img src={item.image} alt="" /></div></div>
            <div className={`${item.small ? `${styles.small} ${styles.aboutCategoryItemLabel}` : styles.aboutCategoryItemLabel}`}>{item.title}</div>
          </div>
        })
      }
    </>
  )
}

